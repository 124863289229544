import * as React from 'react';
import { AddressInterface } from '../../../../../Interface/Order';

export default function AddressTable({
  title,
  address,
}: {
  title: string;
  address: AddressInterface;
}) {
  return (
    <>
      <strong>{title}</strong>
      <br />
      <span>{address.company}</span>
      <br />
      <span>
        {address.first_name} {address.last_name}
      </span>
      <br />
      <span>
        {address.street} {address.number}
      </span>
      <br />
      <span>
        {address.zip} {address.city}
      </span>
      <br />
      <span>{address.country}</span>
      {!!address.gln && address.gln.length > 0 && (
        <>
          <br />
          <span>{address.gln}</span>
        </>
      )}
    </>
  );
}
