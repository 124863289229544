import * as React from 'react';
import { getRequest } from '../../../../Helper/ApiClient';
import { OrderInterface } from '../../../../../Interface/Order';
import AbstractOrderDetails from '../../../../Abstracts/AbstractOrderDetails/AbstractOrderDetails';

export default function NcwOrderDetails({ order_id }: { order_id: number }) {
  const orderRequest = getRequest<OrderInterface>(
    `/api/onlineshop/orders/${order_id}`
  );

  const order = orderRequest.data;
  return (
    <>
      {order && (
        <>
          <h1 className="pt-5">Your Order</h1>
          <div className={'text-center fw-bold fs-2 pb-2'}>
            Thank you for your order.
          </div>
          <AbstractOrderDetails order={order} minimalMode />
        </>
      )}
    </>
  );
}
