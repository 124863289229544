interface BrowserFlags {
  isSafari: boolean;
  isChrome: boolean;
  isFirefox: boolean;
  isEdge: boolean;
  isOpera: boolean;
  isIE: boolean;
  isMobile: boolean;
  isSafariIOS: boolean;
}

type BrowserKey = keyof BrowserFlags;

export const useBrowserCheck = (): {
  checkBrowser: (browserList: BrowserKey[]) => boolean;
} & BrowserFlags => {
  const ua = window.navigator.userAgent;
  const browsers: BrowserFlags = {
    isSafari: /^((?!chrome|android).)*safari/i.test(ua),
    isChrome: /chrome|crios/i.test(ua),
    isFirefox: /firefox/i.test(ua),
    isEdge: /edg/i.test(ua),
    isOpera: /opr/i.test(ua),
    isIE: /msie|trident/i.test(ua),
    isMobile:
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(ua),
    isSafariIOS: false,
  };

  const isIOS = /iphone|ipad|ipod/i.test(ua);
  browsers.isSafariIOS = browsers.isSafari && isIOS;

  const checkBrowser = (browserList: BrowserKey[]): boolean => {
    return browserList.some((browser) => browsers[browser]);
  };

  return { checkBrowser, ...browsers };
};
