import { useMutation, UseMutationResult } from 'react-query';
import makeRequest from './makeRequest';
import { SyntheticEvent } from 'react';

export default function usePostMutation<TData>(url: string): UseMutationResult<
  TData,
  Error,
  BodyInit
> & {
  onSubmitHandler: (event: SyntheticEvent) => void;
  onClickHandler: (event: SyntheticEvent) => void;
} {
  const mutation = useMutation<TData, Error, BodyInit>((body) =>
    makeRequest(url, 'POST', body)
  );

  const onSubmitHandler = function (event: SyntheticEvent) {
    event.preventDefault();
    const target = event.currentTarget;
    if (!(target instanceof HTMLFormElement)) {
      throw Error('Invalid event target! Need HTMLFormElement!');
    }
    const formData = new FormData(target);

    mutation.mutate(formData, {
      onSuccess: () => {
        target.reset();
      },
      onError: (error) => {
        alert(error.message);
      },
    });
  };

  const onClickHandler = function (event: SyntheticEvent) {
    const target = event.currentTarget;

    const formData = new FormData();
    // @ts-ignore
    let dataset = target.dataset ?? {};
    for (const property in dataset) {
      let value = dataset[property];
      if (typeof value !== 'undefined') {
        formData.append(property, value);
      }
    }

    mutation.mutate(formData);
  };

  return { ...mutation, onSubmitHandler, onClickHandler };
}
