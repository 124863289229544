import * as React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { OrderInterface } from '../../../Interface/Order';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import OrderPositions from './OrderPositions/OrderPositions';
import MinimalOrderDetails from './MinimalOrderDetails/MinimalOrderDetails';
import Addresses from './Addresses/Addresses';
import Summary from './Summary/Summary';
export default function AbstractOrderDetails({
  order,
  minimalMode,
}: {
  order: OrderInterface;
  minimalMode?: boolean;
}) {
  return (
    <>
      {minimalMode ? (
        <MinimalOrderDetails order={order} />
      ) : (
        <>
          <Row className="pt-4">
            <Table className="d-inline m-0" borderless>
              <tbody>
                <tr>
                  <td className="fw-bold p-0">Order date</td>
                  <td className="ps-4 p-0">{order.order_date}</td>
                </tr>
                <tr>
                  <td className="fw-bold p-0">Order number</td>
                  <td className="ps-4 p-0">{order.order_id}</td>
                </tr>
                <tr>
                  <td className="fw-bold p-0">Reference number</td>
                  <td className="ps-4 p-0">
                    {order.external_reference &&
                    order.external_reference.length > 1
                      ? order.external_reference
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold p-0">Carrier</td>
                  <td className="ps-4 p-0">
                    {order.carrier ? order.carrier.name : '-'}
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold p-0">Invoice</td>
                  <td className="ps-4 p-0">
                    {order.order_shipped ? (
                      <a
                        className="inherit"
                        href={'/orders/invoicePdf/' + order.order_id}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </a>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
          <Addresses order={order}  />
          <Row className="pt-4">
            <Col>
              <OrderPositions order={order} />
              <Summary order={order} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
