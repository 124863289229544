import * as React from 'react';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { NcwBasket } from '../../../../../Interface/Ncw';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { formatCurrencyEur } from '../../../../Helper/formatter';
import usePatchMutation from '../../../../Helper/usePatchMutation';
export default function Summary({
  basket,
  onUpdateSuccess,
}: {
  basket: NcwBasket;
  onUpdateSuccess: () => void;
}) {
  const patchMutation = usePatchMutation(
    `/api/onlineshop/newcomer-week/basket`
  );

  const trSpace = (
    <tr>
      <td colSpan={99} style={{ height: '10px' }}></td>
    </tr>
  );

  React.useEffect(() => {
    if (patchMutation.isSuccess) {
      onUpdateSuccess();
    }
  }, [patchMutation.isSuccess]);

  return (
    <Row>
      <Col md={5}>
        <Row className={'pb-4'}>
          <Col lg={4}>
            <Col>
              Product label service{' '}
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Attach product label with the nutrition facts in the
                    language of my country to the products (if available)
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </OverlayTrigger>
            </Col>
          </Col>
          <Col lg={8} xl={6}>
            <Form.Check
              inline
              type="checkbox"
              name={'is_label_service'}
              defaultChecked={basket.is_label_service}
              onChange={patchMutation.onUpdateHandler}
            />
            ({formatCurrencyEur(basket.label_price_exc_vat)})
          </Col>
        </Row>
      </Col>

      <Col md={7}>
        <table className={'w-100'}>
          <tbody>
            <tr className={'pb-2 fw-bold'}>
              <td>Subtotal (excl. VAT)</td>
              <td className={'text-end'}>
                {formatCurrencyEur(basket.positions_price_exc_vat)}
              </td>
            </tr>
            {trSpace}

            {basket.positions_price_vat_info !== null && (
              <>
                {Object.keys(basket.positions_price_vat_info).map((vat_nfo) => {
                  return (
                    <tr key={vat_nfo}>
                      <td>
                        Product VAT (
                        {basket.positions_price_vat_info?.[vat_nfo].vat_rate} %
                        of{' '}
                        {formatCurrencyEur(
                          basket.positions_price_vat_info?.[vat_nfo].vat_from
                        )}
                        )
                      </td>
                      <td className="text-end">
                        {formatCurrencyEur(
                          basket.positions_price_vat_info?.[vat_nfo].vat_value
                        )}
                      </td>
                    </tr>
                  );
                })}
                {trSpace}
              </>
            )}

            {basket.positions_deposit_price > 0 && (
              <React.Fragment>
                <tr>
                  <td>Deposit</td>
                  <td className={'text-end'}>
                    {formatCurrencyEur(basket.positions_deposit_price)}
                  </td>
                </tr>
                {trSpace}
              </React.Fragment>
            )}

            {basket.is_label_service && (
              <React.Fragment>
                <tr>
                  <td>Product Label Service</td>
                  <td className={'text-end'}>
                    {formatCurrencyEur(basket.label_price_exc_vat)}
                  </td>
                </tr>
                {basket.label_price_vat_info && (
                  <tr>
                    <td>
                      Product Label Service VAT (
                      {basket.label_price_vat_info.vat_rate} % of{' '}
                      {formatCurrencyEur(basket.label_price_vat_info.vat_from)})
                    </td>
                    <td className="text-end">
                      {formatCurrencyEur(basket.label_price_vat_info.vat_value)}
                    </td>
                  </tr>
                )}
                {trSpace}
              </React.Fragment>
            )}

            <tr>
              <td>Shipping Costs</td>
              <td className={'text-end'}>calculated after checkout</td>
            </tr>
            {trSpace}

            {basket && basket.lemonade_fee_price_exc_vat > 0 && (
              <React.Fragment>
                <tr>
                  <td>Verbruiksbelasting fee (excl. VAT)</td>
                  <td className={'text-end'}>
                    {formatCurrencyEur(basket.lemonade_fee_price_exc_vat)}
                  </td>
                </tr>
                {basket.lemonade_fee_price_vat_info && (
                  <tr key={basket.lemonade_fee_price_vat_info.vat_rate}>
                    <td>
                      Verbruiksbelasting fee VAT (
                      {basket.lemonade_fee_price_vat_info.vat_rate} % of{' '}
                      {formatCurrencyEur(
                        basket.lemonade_fee_price_vat_info.vat_from
                      )}
                      )
                    </td>
                    <td className="text-end">
                      {formatCurrencyEur(
                        basket.lemonade_fee_price_vat_info.vat_value
                      )}
                    </td>
                  </tr>
                )}
                {trSpace}
              </React.Fragment>
            )}

            <tr className={'my-3 fw-bold'}>
              <td>Order total (excl. shipping)</td>
              <td className={'text-end'}>
                {formatCurrencyEur(basket.positions_price_inc_vat)}
              </td>
            </tr>
            {trSpace}
          </tbody>
        </table>
      </Col>
    </Row>
  );
}
