import React from 'react';
import { INewComerWeekProduct } from '../../../../ProductsSlideshow/ProductsSlideshow';
import { ViewportType } from '../../../../../../../Hooks/useViewport';
import { AnimationStepsTypes } from '../../../ProductPages';
import ProductInformationContent from './ProductInformationContent/ProductInformationContent';
import SnapSection from '../../../../../Abstracts/SnapSection/SnapSection';

//@ts-ignore
// import styles from './ProductSlidePageInformation.module.scss?module';

const ProductSlidePageInformation = ({
  ncwProduct,
  viewport,
  activeProduct,
  animationSteps,
}: {
  ncwProduct: INewComerWeekProduct;
  viewport?: ViewportType;
  activeProduct?: boolean;
  animationSteps?: AnimationStepsTypes;
}) => {
  return (
    <>
      {viewport === 'desktop' ? (
        <div
          className={'CustomScrollbar'}
          style={{
            display: 'flex',
            height: 'inherit',
            backgroundColor: ncwProduct.colorBackground || 'black',
            minHeight: '100%',
            minWidth: '100%',
            maxHeight: '100%',
            overflow: 'auto',
            position: 'absolute',
            transition: 'all 1s',
            transform:
              activeProduct &&
              animationSteps &&
              animationSteps.showProductInformationSection
                ? 'translate3d(100%, 0, 0)'
                : 'translate3d(100%, 100%, 0)',
            opacity:
              activeProduct &&
              animationSteps &&
              animationSteps.showProductInformationSection
                ? 1
                : 0,
          }}
        >
          <div
            style={{
              // fontFamily: 'newcomer-week',
              color: ncwProduct.colorText || 'white',
              fontSize: '1rem',
              width: '100%',
              padding: '2.5rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <ProductInformationContent ncwProduct={ncwProduct} isDesktop />
          </div>
        </div>
      ) : (
        <SnapSection>
          <div style={{ columns: '450px' }}>
            <ProductInformationContent ncwProduct={ncwProduct} />
          </div>
        </SnapSection>
      )}
    </>
  );
};

export default ProductSlidePageInformation;
