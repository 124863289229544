import { useEffect, useRef, useState } from 'react';

const useInView = (
  ref: React.MutableRefObject<Element | null>,
  options: IntersectionObserverInit = {}
): boolean => {
  const [isElementInView, setIsElementInView] = useState(false);
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => {
      setIsElementInView(entry.isIntersecting);
    }, options);

    const currentObserver = observerRef.current;
    if (ref.current) {
      currentObserver.observe(ref.current);
    }

    return () => {
      currentObserver?.disconnect();
    };
  }, [ref, options]);

  return isElementInView;
};

export default useInView;
