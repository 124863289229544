import React from 'react';

// @ts-ignore
import styles from './Header.module.scss?module';
import { Col, Container, Row } from 'react-bootstrap';
import Link from '../../../Abstracts/Link/Link';
import {
  faHouse,
  faBagShopping,
  faHeart,
} from '@fortawesome/pro-solid-svg-icons';
import NavigationButton from '../Components/NavigationButton/NavigationButton';
import { useUser } from '../../../Context/UserContext';
import { getRequest } from '../../../Helper/ApiClient';
import { formatCurrencyEur } from '../../../Helper/formatter';

export default function Header() {
  const { user } = useUser();
  const basketHeaderRequest = getRequest<{
    total_price: number;
    total_quantity: number;
  }>('/api/onlineshop/newcomer-week/basket-header');
  return (
    <>
      <div style={{ height: '56px', opacity: '0' }}>Save space header</div>
      <Container
        fluid
        className={styles.Header}
        style={{
          position: 'fixed',
          zIndex: 10,
          top: 0,
          left: 0,
          width: '100%',
          // opacity: '.5',
        }}
      >
        <Row
          style={{
            maxWidth: '100vw',
            overflowX: 'hidden',
            minHeight: '56px',
            maxHeight: '56px',
          }}
        >
          <Col xs={3} className="align-items-center d-flex">
            <Link
              to={'/newcomer-week/home'}
              inheritColor
              className={`${styles.Nav} p-0`}
            >
              <NavigationButton icon={faHouse} />
            </Link>
          </Col>
          <Col
            xs={6}
            className="d-flex align-items-center justify-content-center overflow-hidden"
            style={{ maxHeight: '56px' }}
          >
            <Link
              to={'/newcomer-week/home'}
              inheritColor
              className={`${styles.Nav} p-0`}
            >
              <span
                style={{
                  fontFamily: 'newcomer-week, Arial, sans-serif',
                  fontSize: 'clamp(1.2rem, 1rem + 1.5vw, 2.5rem)',
                  textTransform: 'uppercase',
                  letterSpacing: '0.5px',
                }}
              >
                Newcomer Week
              </span>
            </Link>
          </Col>
          <Col
            xs={3}
            className={
              'text-end d-flex gap-2 align-items-center justify-content-end'
            }
          >
            {user && (
              <>
                {/*<Link
                  to={'/newcomer-week/favorites'}
                  inheritColor
                  // className={styles.Nav}
                >
                  <NavigationButton icon={faHeart} />
                </Link>*/}
                <Link
                  className="d-flex align-items-center"
                  to={'/newcomer-week/basket'}
                  inheritColor
                  // className={styles.Nav}
                >
                  <NavigationButton
                    icon={faBagShopping}
                    badge={
                      basketHeaderRequest.data &&
                      basketHeaderRequest.data.total_quantity > 0
                        ? basketHeaderRequest.data.total_quantity.toString()
                        : undefined
                    }
                  />
                  &nbsp;
                  {basketHeaderRequest.data &&
                    basketHeaderRequest.data.total_price > 0 && (
                      <small className="fw-bold fst-italic d-none d-sm-none d-md-block">
                        {formatCurrencyEur(
                          basketHeaderRequest.data.total_price
                        )}
                      </small>
                    )}
                </Link>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
