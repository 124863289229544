import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getRequest } from '../../../Helper/ApiClient';
import { formatCurrencyEur } from '../../../Helper/formatter';
import Table from '../../../Abstracts/TableElements/Table/Table';

export interface OpenBackorder {
  brand_erp_id: number;
  brand: string;
  product_size_erp_id: number;
  product_size: string;
  product_attribute_erp_id: number;
  product_attribute: string;
  open_backorder_quantity: number;
  open_backorder_price: number;
  discount?: string;
}

export default function BackorderQuantities() {
  const ncwBasket = getRequest<OpenBackorder[]>(
    '/api/onlineshop/open-backorder'
  );
  return (
    <Container>
      <Row className="pt-4">
        <h1>My backorder quantities</h1>
        <p>
          This table shows the quantities of backordered products still pending.
          These items will automatically be added to your sales orders once they
          arrive in our warehouse.
        </p>
      </Row>
      <Row className="pt-4">
        <Col>
          <Table striped className="">
            <thead>
              <tr>
                <th>Brand</th>
                <th>Product</th>
                <th>Flavour</th>
                <th className="text-end">Discount</th>
                <th className="text-end">Price</th>
                <th className="text-end">Remaining Quantity</th>
              </tr>
            </thead>
            <tbody>
              {ncwBasket?.data?.map((openBackOrder) => {
                return (
                  <tr key={openBackOrder.product_attribute_erp_id}>
                    <td>{openBackOrder.brand}</td>
                    <td>{openBackOrder.product_size}</td>
                    <td>{openBackOrder.product_attribute}</td>
                    <td className="text-end">{openBackOrder.discount}</td>
                    <td className="text-end">
                      {formatCurrencyEur(openBackOrder.open_backorder_price)}
                    </td>
                    <td className="text-end">
                      {openBackOrder.open_backorder_quantity}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
