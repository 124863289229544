import * as React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
// @ts-ignore
import styles from './NcwLogin.module.scss?module';
import LoginForm from '../../../../Abstracts/Forms/LoginForm/LoginForm';
const IMAGE_SRC = 'https://files.naskorsports.com/shop/newcomer-week/';
export default function NcwLogin() {
  return (
    <>
      <Container fluid style={{ position: 'relative' }}>
        <div
          className={styles.NcwHomeBGImage}
          style={{
            backgroundImage: `url("${IMAGE_SRC}home/ncw-home-bg.jpg")`,
          }}
        ></div>
        <div className={styles.Overlay} aria-label="overlay" />
        <Row className="justify-content-center">
          <Col lg={4} md={5} sm={6}>
            <div style={{ height: '100vh' }}>
              <div aria-label="spacer" style={{ height: '4rem' }} />
              <div aria-label="spacer" style={{ height: '4rem' }} />
              <Card>
                <Card.Body>
                  <LoginForm />
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
