import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import Link from '../../../../Abstracts/Link/Link';
export default function EmptyBasketContent() {
  return (
    <>
      <Row className={'pt-5 pb-2'}>
        <Col>
          <h2 className={'text-center'}>Your basket is empty</h2>
        </Col>
      </Row>
      <Row>
        <Col className={'text-center'}>
          <Link
            to={'/newcomer-week/home'}
            underline
            className={'me-4 text-muted fst-italic'}
            style={{ fontSize: '.8rem' }}
          >
            Continue shopping
          </Link>
        </Col>
      </Row>
    </>
  );
}
