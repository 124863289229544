import React, { CSSProperties } from 'react';
import { Image } from 'react-bootstrap';

//@ts-ignore
import styles from './ProductSlidePageImage.module.scss?module';
import { INewComerWeekProduct } from '../../../../ProductsSlideshow/ProductsSlideshow';
import SnapSection from '../../../../../Abstracts/SnapSection/SnapSection';
import { useBrowserCheck } from '../../../../../../../Hooks/useBrowserCheck';

const ProductSlidePageImage = ({
  ncwProduct,
}: {
  ncwProduct: INewComerWeekProduct;
}) => {
  const productData = ncwProduct.product;
  const { checkBrowser } = useBrowserCheck();

  const browserSpecificStyles: CSSProperties = checkBrowser(['isSafariIOS'])
    ? {
        bottom: '8.5%',
      }
    : {
        bottom: '7%',
      };
  return (
    <SnapSection
      noOverflow
      className="d-flex align-items-center position-relative justify-content-center"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          className={'w-100'}
          draggable={false}
          src={productData.image_transparent_url}
          style={{ maxWidth: '700px' }}
        />
      </div>
      <div className="h-100 d-flex justify-content-center align-items-end">
        {ncwProduct.discountPromotion && (
          <span
            className="bg-danger"
            style={{
              color: '#eee',
              position: 'absolute',
              padding: '0.2rem 0.5rem',
              top: '10px',
              left: '10px',
              borderRadius: '5px',
              fontSize: '1.5rem',
            }}
          >
            {ncwProduct.discountPromotion}% off
          </span>
        )}
        <div
          style={{ ...browserSpecificStyles }}
          className={styles.ScrollDownAnimationCotainer}
        >
          <div className={`${styles.ScrolldownArrows}`}>
            <div
              className={`${styles.ScrolldownArrow} ${styles.ScrolldownArrow1}`}
            ></div>
            <div
              className={`${styles.ScrolldownArrow} ${styles.ScrolldownArrow2}`}
            ></div>
            <div
              className={`${styles.ScrolldownArrow} ${styles.ScrolldownArrow3}`}
            ></div>
          </div>
        </div>
      </div>
    </SnapSection>
  );
};

export default ProductSlidePageImage;
