import * as React from 'react';
import { Col, FormControl, Image, Row } from 'react-bootstrap';
import { ProductSizeData } from '../../../Products/ProductsSlideshow/ProductsSlideshow';
import { formatCurrency } from '../../../../../Helper/formatter';

//@ts-ignore
import styles from './NcwProductSize.module.scss?module';

export default function NcwProductSize({
  productSizeData,
  color,
  backgroundColor,
  componentRef,
  onChange,
  isBrand = false,
}: {
  productSizeData: ProductSizeData;
  color?: string;
  backgroundColor?: string;
  componentRef?: (instance: HTMLInputElement | null) => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  isBrand?: boolean;
}) {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      onChange && onChange(e);
    } else {
      e.target.value = value.replace(/\D/g, '');
      const event = new Event('input', { bubbles: true });
      e.target.dispatchEvent(event);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/\d/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Row className={styles.SizeRow}>
        <Col lg={6}>
          <Row className={styles.SizeNameAboveImage}>
            <Col>
              <h4>{productSizeData.name}</h4>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className={!isBrand ? 'd-none' : ''}>
              <Image
                alt={'Image of ' + productSizeData.name}
                className="w-100"
                src={productSizeData.image_transparent_url}
              />
            </Col>
            <Col xs={isBrand ? 6 : 12}>
              <div className={styles.SizeNameAbovePromo}>
                <h4>{productSizeData.name}</h4>
              </div>
              <div className={styles.SizePromo}>
                {productSizeData.promotion_discount}% Off
              </div>
              <div className={styles.SizePriceReference}>
                {formatCurrency(productSizeData.regular_price)}
                <span>&nbsp;EUR</span>
              </div>
              <div className={styles.SizePriceDiscounted}>
                {formatCurrency(productSizeData.price)}
                <span>&nbsp;EUR</span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={6}>
          <table className={styles.SizeFlavoursTable}>
            <tbody>
              {productSizeData.productAttributes.map((productAttributeData) => (
                <React.Fragment key={productAttributeData.erp_id}>
                  <tr className={styles.SizeFlavoursTableRow}>
                    <td>{productAttributeData.name}</td>
                    <td className={'text-end'} style={{ width: '30%' }}>
                      <FormControl
                        id={`desktop_${productAttributeData.erp_id}`}
                        ref={componentRef}
                        className={styles.SizeFlavoursTableQty}
                        min={1}
                        type="text"
                        inputMode="numeric"
                        pattern="\d*"
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        onPaste={(e) => {
                          const pastedData = e.clipboardData.getData('text');
                          if (!/^\d*$/.test(pastedData)) {
                            e.preventDefault();
                          }
                        }}
                        autoComplete="off"
                        data-price={productSizeData.price}
                        data-product_attribute_id={productAttributeData.id}
                        style={{
                          backgroundColor: backgroundColor || '#333333',
                          color: color || 'white',
                        }}
                        placeholder={'0'}
                      />
                    </td>
                  </tr>
                  <tr className={styles.SizeFlavoursDividerRow}>
                    <td colSpan={2} className={'pt-1'}></td>
                  </tr>
                  {/*<Row
                className={styles.SizeQtyRow}
                key={productAttributeData.erp_id}
              >
                <Col xs={8}>{productAttributeData.name}</Col>
                <Col xs={4}>
                  <FormControl
                    id={`desktop_${productAttributeData.erp_id}`}
                    ref={componentRef}
                    className={styles.SizeQtyInput}
                    min={1}
                    type={'text'}
                    inputMode={'numeric'}
                    onChange={onChange}
                    autoComplete="off"
                    data-price={productSizeData.price}
                    data-product_attribute_id={productAttributeData.id}
                    style={{
                      backgroundColor: backgroundColor || '#333333',
                      color: color || 'white',
                    }}
                    placeholder={'0'}
                  />
                </Col>
              </Row>*/}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
      {/*<Row className={styles.SizeDividerRow}>*/}
      {/*  <Col className={'py-3'}>*/}
      {/*    <hr />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      {/*<div
        className={
          isBrand
            ? `d-flex flex-column flex-xxl-row justify-content-between gap-4 gap-xxl-4`
            : ''
        }
      >
        {isBrand && productSizeData.image_transparent_url && (
          <div className="d-flex justify-content-center align-items-start">
            <Image
              alt="product-size.image"
              className="w-100"
              style={{ maxWidth: '300px' }}
              src={productSizeData.image_transparent_url}
            />
          </div>
        )}

        {isBrand ? (
          <>
            <div
              className={`${styles.PromoPriceContainer} py-4 d-flex flex-column gap-4 align-items-end`}
            >
              <div
                className="bg-danger d-flex justify-content-center align-items-center"
                style={{
                  width: '250px',
                  height: '50px',
                  padding: '10px',
                  maxWidth: '50vw',
                  fontSize: 'larger',
                }}
              >
                {productSizeData.promotion_discount}% off
              </div>
              <div className="d-flex gap-2">
                <h5 className="m-0" style={{ textDecoration: 'line-through' }}>
                  {productSizeData.regular_price}
                </h5>
                <span style={{ fontSize: '12px' }}>EUR</span>
              </div>
              <div className="d-flex">
                <h2 className="text-danger m-0">
                  {formatCurrency(productSizeData.price)}
                </h2>
                <span style={{ fontSize: '18px' }}>EUR</span>
              </div>
            </div>

            <div
              className={`${styles.FlavorContainer} d-flex flex-column flex-grow-1 gap-4`}
            >
              <h4>{productSizeData.name}</h4>
              {FlavorAndQuantityInput(
                productSizeData,
                componentRef,
                onChange,
                backgroundColor,
                color
              )}
            </div>
          </>
        ) : (
          <div className="px-md-4 gap-4 d-flex w-100 flex-column">
            <div className={`py-4 d-flex flex-column gap-4 align-items-end`}>
              <div className="d-flex w-100 justify-content-between">
                <h4 style={{ wordBreak: 'break-word' }}>
                  {productSizeData.name}
                </h4>
                <div
                  className="bg-danger d-flex justify-content-center align-items-center"
                  style={{
                    width: '250px',
                    height: '50px',
                    padding: '10px',
                    maxWidth: '50vw',
                    fontSize: 'larger',
                  }}
                >
                  {productSizeData.promotion_discount}% off
                </div>
              </div>

              <div className="d-flex gap-2">
                <h5 className="m-0" style={{ textDecoration: 'line-through' }}>
                  {productSizeData.regular_price}
                </h5>
                <span style={{ fontSize: '12px' }}>EUR</span>
              </div>
              <div className="d-flex">
                <h2 className="text-danger m-0">
                  {formatCurrency(productSizeData.price)}
                </h2>
                <span style={{ fontSize: '18px' }}>EUR</span>
              </div>
            </div>
            {FlavorAndQuantityInput(
              productSizeData,
              componentRef,
              onChange,
              backgroundColor,
              color
            )}
          </div>
        )}
      </div>*/}
    </>
  );
}

// const FlavorAndQuantityInput = (
//   productSizeData,
//   componentRef,
//   onChange,
//   backgroundColor,
//   color
// ) => {
//   return (
//     <div className="d-flex flex-column gap-2">
//       {productSizeData.productAttributes.map((productAttributeData) => (
//         <div
//           className="d-flex justify-content-between align-items-center"
//           key={productAttributeData.erp_id}
//         >
//           <span>{productAttributeData.name}</span>
//           <FormControl
//             id={`desktop_${productAttributeData.erp_id}`}
//             ref={componentRef}
//             className={styles.QuantityInput}
//             min={1}
//             type={'number'}
//             inputMode={'numeric'}
//             onChange={onChange}
//             autoComplete="off"
//             data-price={productSizeData.price}
//             data-product_attribute_id={productAttributeData.id}
//             style={{
//               backgroundColor: backgroundColor || 'black',
//               color: color || 'white',
//             }}
//             placeholder={'0'}
//           />
//         </div>
//       ))}
//     </div>
//   );
// };
