import { useEffect } from 'react';

const useNewcomerWeekHTMLConfig = (className: string) => {
  useEffect(() => {
    const rootElement = document.getElementsByTagName('html')[0];
    rootElement.className = className;
    rootElement.style.scrollSnapType = 'y mandatory';
    rootElement.style.scrollPaddingTop = '56px';
    // rootElement.style.overscrollBehavior = 'none';
    //@ts-ignore
    rootElement.style.scrollbarWidth = 'none';

    return () => {
      rootElement.style.scrollSnapType = '';
    };
  }, []);
};

export default useNewcomerWeekHTMLConfig;
