import React, {
  useState,
  useEffect,
  RefObject,
  ReactNode,
  CSSProperties,
} from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { useBrowserCheck } from '../../../../Hooks/useBrowserCheck';

interface ScrollToTopWrapperProps {
  children: ReactNode;
  containerRef?: RefObject<HTMLElement>;
}

const ScrollToTopButton: React.FC<{
  onClick: () => void;
  isVisible: boolean;
}> = ({ onClick, isVisible }) => {
  const { checkBrowser } = useBrowserCheck();
  const browserSpecificStyles: CSSProperties = checkBrowser(['isSafariIOS'])
    ? {
        bottom: '8.5rem',
      }
    : {
        bottom: '6rem',
      };

  return ReactDOM.createPortal(
    <button
      onClick={onClick}
      className="fw-bold btn btn-primary rounded-circle shadow-lg"
      style={{
        ...browserSpecificStyles,
        position: 'fixed',
        right: isVisible ? '2.5rem' : '-4rem',
        width: '3rem',
        height: '3rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 0.3s',
        zIndex: 1050,
      }}
      aria-label="Scroll to top"
    >
      <span>
        <FontAwesomeIcon size="xl" icon={faChevronUp} />
      </span>
      <span>TOP</span>
    </button>,
    document.body
  );
};

export const ScrollToTopWrapper: React.FC<ScrollToTopWrapperProps> = ({
  children,
  containerRef,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const container = containerRef?.current || window;

    const toggleVisibility = () => {
      const scrollElement = containerRef?.current ?? document.documentElement;
      const scrollTop = scrollElement.scrollTop;
      const viewportHeight =
        containerRef?.current?.clientHeight ?? window.innerHeight;

      if (scrollTop > viewportHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    container.addEventListener('scroll', toggleVisibility);

    return () => container.removeEventListener('scroll', toggleVisibility);
  }, [containerRef]);

  const scrollToTop = () => {
    const scrollElement = containerRef?.current ?? window;
    scrollElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {children}
      {<ScrollToTopButton onClick={scrollToTop} isVisible={isVisible} />}
    </>
  );
};
