import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ScrollToTopWrapper } from '../ScrollToTopWrapper/ScrollToTopWrapper';

//@ts-ignore
import style from './SnapSection.module.scss?module';

export default function SnapSection({
  children,
  minHeight,
  className,
  noOverflow,
  scrollToTop,
  activeItem,
}: {
  children: React.ReactNode;
  minHeight?: string;
  className?: string;
  noOverflow?: boolean;
  scrollToTop?: boolean;
  activeItem?: boolean;
}) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const styles: React.CSSProperties = noOverflow
    ? {
        minHeight: minHeight ? minHeight : 'calc(100vh - 112px)',
      }
    : {
        maxHeight: 'calc(100vh - 112px)',
        minHeight: 'calc(100vh - 112px)',
        overflow: 'auto',
      };

  return (
    <Row
      ref={containerRef}
      className={`CustomScrollbar ${className || ''} ${
        style.ScrollSnapContainer
      }`}
      style={{ ...styles }}
    >
      {scrollToTop && activeItem ? (
        <ScrollToTopWrapper containerRef={containerRef}>
          <Col>{children}</Col>
        </ScrollToTopWrapper>
      ) : (
        <Col>{children}</Col>
      )}
    </Row>
  );
}
