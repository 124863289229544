import * as React from 'react';
import { Form } from 'react-bootstrap';
import { NcwBasketPosition } from '../../../../../Interface/Ncw';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import usePatchMutation from '../../../../Helper/usePatchMutation';
import debounce from '../../../../Helper/debounce';

import useDeleteMutation from '../../../../Helper/useDeleteMutation';
export default function QuantityInput({
  ncwBasketPosition,
  onQtyUpdateSuccess,
}: {
  ncwBasketPosition: NcwBasketPosition;
  onQtyUpdateSuccess: () => void;
}) {
  const [qty, setQty] = React.useState<number>(ncwBasketPosition.quantity);

  const patchMutation = React.useCallback(
    debounce((newQty: number) => {
      positionMutation.mutate(JSON.stringify({ quantity: newQty }), {
        onSuccess: () => {
          onQtyUpdateSuccess();
        },
      });
    }, 500),
    []
  );

  const updateQty = (newQty: number) => {
    setQty(newQty);
    patchMutation(newQty);
  };

  const positionMutation = usePatchMutation(
    `/api/onlineshop/newcomer-week/basket/positions/${ncwBasketPosition.id}`
  );
  const handleQtyClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.dataset.name == 'increase') {
      updateQty(qty + 1);
    } else {
      updateQty(qty - 1);
    }
  };

  const handleQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateQty(Number(e.currentTarget.value));
  };

  const positionDeleteMutation = useDeleteMutation(
    `/api/onlineshop/newcomer-week/basket/positions/${ncwBasketPosition.id}`
  );
  const handleRemoveClick = (e: React.MouseEvent<HTMLDivElement>) => {
    positionDeleteMutation.mutate(null, {
      onSuccess: () => {
        onQtyUpdateSuccess();
      },
    });
  };

  return (
    <div className="d-flex align-items-center">
      <div role="button" onClick={handleQtyClick} data-name="decrease">
        <FontAwesomeIcon icon={faMinus} />
      </div>
      <Form.Control
        className={'mx-2 w-100 text-center'}
        value={qty}
        onChange={handleQtyChange}
      />
      <div role="button" onClick={handleQtyClick} data-name="increase">
        <FontAwesomeIcon icon={faPlus} />
      </div>
      &nbsp;
      <div role="button" onClick={handleRemoveClick}>
        <FontAwesomeIcon icon={faTrash} />
      </div>
    </div>
  );
}
