import { faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import React from 'react';
import useScroll from '../../Hooks/useScroll';
// @ts-ignore
import style from './ScrollToTopBadge.module.scss?module';
import { Spring, animated } from '@react-spring/web';

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

type ScrollToTopBadgeProps = {
  excludedRoutes?: string[];
};

const ScrollToTopBadge = ({ excludedRoutes }: ScrollToTopBadgeProps) => {
  const location = useLocation();
  const { x, y } = useScroll();

  const isExcluded =
    excludedRoutes &&
    excludedRoutes.some((route) => location.pathname.startsWith(route));

  if (isExcluded || y <= window.innerHeight) {
    return null;
  }

  return y > window.innerHeight ? (
    <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
      {(props) => (
        <animated.div style={props}>
          <a
            className={[
              'position-fixed fw-bold bg-primary text-white text-decoration-none cursor-pointer d-flex flex-column justify-content-center align-items-center',
              style.scrollToTop,
            ].join(' ')}
            onClick={scrollToTop}
          >
            <FontAwesomeIcon icon={faChevronUp} />
            TOP
          </a>
        </animated.div>
      )}
    </Spring>
  ) : (
    <></>
  );
};

export default ScrollToTopBadge;
