import * as React from 'react';
import { Table } from 'react-bootstrap';
import { OrderInterface } from '../../../../Interface/Order';
import {
  formatCurrencyEur,
  formatFloat2Digits,
} from '../../../Helper/formatter';
export default function Summary({
  order,
  totalOnly,
  showTotalQuantity,
}: {
  order: OrderInterface;
  totalOnly?: boolean;
  showTotalQuantity?: boolean;
}) {
  const totalQuantity = order.order_positions?.reduce(
    (acc: number, position): number => {
      return acc + position.quantity;
    },
    0
  );
  return (
    <div className="text-end">
      <Table className="d-inline" borderless>
        <tbody>
          {!totalOnly && (
            <>
              {order.total_price_exclusive_tax && (
                <tr>
                  <td />
                  <td className="p-0">Subtotal (excl. VAT)</td>
                  <td className="p-0 ps-4">
                    {formatCurrencyEur(order.total_price_exclusive_tax)}
                  </td>
                </tr>
              )}
              {order.total_position_tax_price_per_tax_rate &&
                Object.keys(order.total_position_tax_price_per_tax_rate).map(
                  (vat_rate, i) => {
                    return (
                      <tr key={i}>
                        <td />
                        <td className="p-0">
                          Product VAT ({vat_rate}% of{' '}
                          {formatCurrencyEur(
                            order.total_position_price_per_tax_rate[vat_rate]
                          )}
                          )
                        </td>
                        <td className="p-0 ps-4">
                          {formatCurrencyEur(
                            order.total_position_tax_price_per_tax_rate[
                              vat_rate
                            ]
                          )}
                        </td>
                      </tr>
                    );
                  }
                )}

              <tr data-name="shipping_cost">
                <td />
                <td className="p-0">Shipping Costs</td>
                <td className="p-0 ps-4">
                  {formatCurrencyEur(order.shipping_cost)}
                </td>
              </tr>

              {order.shipping_costs_tax_price > 0 ? (
                <tr data-name="shipping_cost_vat">
                  <td />
                  <td className="p-0">
                    Shipping Cost VAT ({order.shipping_costs_tax_rate}% of{' '}
                    {formatCurrencyEur(order.shipping_cost)})
                  </td>
                  <td className="p-0 ps-4">
                    {formatCurrencyEur(order.shipping_costs_tax_price)}
                  </td>
                </tr>
              ) : (
                <></>
              )}

              {order.label_price > 0 ? (
                <tr data-name="label_service">
                  <td />
                  <td>Product Label Service</td>
                  <td className="text-end">
                    {formatCurrencyEur(order.label_price)}
                  </td>
                </tr>
              ) : (
                <></>
              )}

              {order.label_price > 0 ? (
                <tr data-name="label_service_vat">
                  <td />
                  <td>
                    Product Label Service VAT ({order.label_price_tax_rate}% of{' '}
                    {formatCurrencyEur(order.label_price)})
                  </td>
                  <td className="text-end">
                    {formatCurrencyEur(order.label_price_tax_price)}
                  </td>
                </tr>
              ) : (
                <></>
              )}

              {order.total_lemonade_fee_price_exc > 0 ? (
                <tr data-name="label_lemonade_fee">
                  <td />
                  <td>Verbruiksbelasting fee (exclusive VAT)</td>
                  <td className="text-end">
                    {formatCurrencyEur(order.total_lemonade_fee_price_exc)}
                  </td>
                </tr>
              ) : (
                <></>
              )}

              {order.total_lemonade_fee_tax_price > 0 ? (
                <tr data-name="label_lemonade_fee_tax">
                  <td />
                  <td>
                    VAT {formatFloat2Digits(order.lemonade_fee_vat)}% from{' '}
                    {formatCurrencyEur(order.total_lemonade_fee_price_exc)}
                  </td>
                  <td className="text-end">
                    {formatCurrencyEur(order.total_lemonade_fee_tax_price)}
                  </td>
                </tr>
              ) : (
                <></>
              )}

              {order.total_deposit_price_exclusive_tax > 0 ? (
                <tr data-name="label_deposit">
                  <td />
                  <td>Deposit</td>
                  <td className="text-end">
                    {formatCurrencyEur(order.total_deposit_price_exclusive_tax)}
                  </td>
                </tr>
              ) : (
                <></>
              )}

              {order.document_fee > 0 ? (
                <tr data-name="label_document_fee">
                  <td />
                  <td>Export document fee</td>
                  <td className="text-end">
                    {formatCurrencyEur(order.document_fee)}
                  </td>
                </tr>
              ) : (
                <></>
              )}

              {order?.credit_card_fee_price > 0 ? (
                <>
                  <tr data-name="credit_card_fee">
                    <td />
                    <td className="p-0">
                      Credit card fee ({order?.credit_card_percent}%)
                    </td>
                    <td className="p-0 ps-4">
                      {formatCurrencyEur(order?.credit_card_fee_price)}
                    </td>
                  </tr>
                  {order?.credit_card_vat_price > 0 ? (
                    <tr data-name="credit_card_fee_vat">
                      <td />
                      <td className="p-0">
                        Credit card fee VAT ({order?.credit_card_percent}% of{' '}
                        {formatCurrencyEur(order?.credit_card_fee_price)})
                      </td>
                      <td className="p-0 ps-4">
                        {formatCurrencyEur(order?.credit_card_vat_price)}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}
          <tr data-name="total">
            {showTotalQuantity ? (
              <td className="p-0 pt-4 fw-bold">
                Total Quantity: {totalQuantity}
              </td>
            ) : (
              <td />
            )}
            <td className="p-0 pt-4 fw-bold ps-4">Total</td>
            <td className="p-0 pt-4 fw-bold ps-4">
              {formatCurrencyEur(order.total_price_inclusive_tax)}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
