import React, { useEffect, useRef, useState } from 'react';

// @ts-ignore
import styles from './LandingPage.module.scss?module';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Button from '../../../Abstracts/Button/Button';
import Countdown from '../../../Abstracts/Countdown/Countdown';
import useInView from '../../../Hooks/useInView';

export default function LandingPage() {
  const [isPageImgAndDateInView, setIsPageImgAndDateInView] = useState(false);
  const imgCardParentRef = useRef<HTMLDivElement>(null);
  const titleAndDescRef = useRef(null);
  const ExploreSectionRef = useRef(null);
  const isTitleAndDescInView = useInView(titleAndDescRef);
  const isExploreSectionInView = useInView(ExploreSectionRef);
  const isImgCardsInView = useInView(imgCardParentRef);

  useEffect(() => {
    if (isPageImgAndDateInView == false) {
      setTimeout(() => {
        setIsPageImgAndDateInView(true);
      }, 100);
    }
  }, [isPageImgAndDateInView]);

  return (
    <>
      <Container className="d-flex gap-4 gap-lg-5 flex-column">
        <Row>
          <Col
            className={
              isPageImgAndDateInView
                ? styles.VisiblePageImg
                : styles.HiddenPageImg
            }
          >
            {/* todo: der sftp imges src muss in einem var */}
            <Image
              className={'w-100 d-none d-md-block'}
              src={`https://files.naskorsports.com/shop/newcomer-week/landing-page/banner-desktop.jpg`}
            />
            <Image
              className={'w-100 d-md-none'}
              src={`https://files.naskorsports.com/shop/newcomer-week/landing-page/banner-mobile.jpg`}
            />
          </Col>
        </Row>
        <Row style={{ contain: 'paint' }}>
          <Col
            className={`${
              isPageImgAndDateInView ? styles.VisibleDate : styles.HiddenDate
            } text-center`}
          >
            <Countdown dateString={'2024-09-16T00:00:00.000+02:00'} />
          </Col>
        </Row>
        <div
          className="d-flex gap-4 gap-lg-5 flex-column overflow-hidden"
          ref={titleAndDescRef}
        >
          <Row>
            <Col
              className={
                isTitleAndDescInView
                  ? styles.VisiblePageTitle
                  : styles.HiddenPageTitle
              }
            >
              <h1>Find Your Perfect Matches</h1>
            </Col>
          </Row>
          <Row>
            <Col
              className={`${
                isTitleAndDescInView
                  ? styles.VisiblePageDesc
                  : styles.HiddenPageDesc
              }`}
            >
              <p className="m-0">
                From 16.09.24 to 22.09.24, experience the exciting world of new
                brands and innovative products. Only in our Newcomer Week, you
                can expect special offers and exclusive free samples for all
                participants. Get to know selected brands, their potential and
                their values and find your perfect match for you and your
                customers.
              </p>
              <br />
              <p className="m-0">
                Click on the button and save the event directly in your
                calendar.
              </p>
            </Col>
          </Row>
          <Row aria-label="spacer" />
          <Row>
            <Col
              className={`text-center ${
                isTitleAndDescInView
                  ? styles.VisibleCalendarBtn
                  : styles.HiddenCalendarBtn
              }`}
            >
              <a
                href={
                  'https://files.naskorsports.com/shop/newcomer-week/landing-page/newcomer-week-event.ics'
                }
                download
              >
                <Button
                  className={styles.AddToCalendarButton}
                  style={{ width: '18rem', height: '3rem' }}
                >
                  Add to calendar
                </Button>
              </a>
            </Col>
          </Row>
        </div>
        <Row aria-label="spacer" />
        <Row ref={imgCardParentRef}>
          {AnimatedCards({ isImgCardsInView: isImgCardsInView })}
        </Row>
        <Row aria-label="spacer" />
        <Row
          className="gap-4 gap-md-0"
          style={{ contain: 'paint' }}
          ref={ExploreSectionRef}
        >
          <Col
            xs={12}
            md={6}
            className={`d-flex align-items-center flex-column gap-4 justify-content-center ${
              isExploreSectionInView
                ? styles.VisibleExploreDesc
                : styles.HiddenExploreDesc
            }`}
          >
            <p className="m-0 text-center">
              Take the chance to present the latest trends and products to gain
              a competitive advantage. Don't miss this unique opportunity to get
              to know the highlights of the industry and benefit from unbeatable
              promotions. The offers are only valid during Newcomer Week. Save
              the date and don't miss out!
            </p>
            <div>
              <a
                href={
                  'https://files.naskorsports.com/shop/newcomer-week/landing-page/newcomer-week-event.ics'
                }
                download
              >
                <Button
                  className={styles.AddToCalendarButton}
                  style={{ width: '18rem', height: '3rem' }}
                >
                  Add to calendar
                </Button>
              </a>
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            className={`${
              isExploreSectionInView
                ? styles.VisibleExploreImg
                : styles.HiddenExploreImg
            }`}
          >
            <img
              className="w-100"
              src="https://files.naskorsports.com/shop/newcomer-week/landing-page/newcomer-week-landingpage-LP-pre-Event.jpg"
              alt="LP pre Event image"
            />
          </Col>
        </Row>
        <Row aria-label="spacer" />
      </Container>
    </>
  );
}

const ANIMATION_SPEED = [
  {
    angleSpeed: '5',
    imgSrc:
      'https://files.naskorsports.com/shop/newcomer-week/landing-page/special-promotions.png',
  },
  {
    angleSpeed: '3.5',
    imgSrc:
      'https://files.naskorsports.com/shop/newcomer-week/landing-page/sample-box.png',
  },
  {
    angleSpeed: '2',
    imgSrc:
      'https://files.naskorsports.com/shop/newcomer-week/landing-page/amazing-brands.png',
  },
] as const;

const AnimatedCards = ({ isImgCardsInView }: { isImgCardsInView: boolean }) => {
  return ANIMATION_SPEED.map((element, index) => {
    return (
      <Col
        className={`${styles.ImgCardsParent} ${
          isImgCardsInView ? styles.VisibleImgCards : styles.HiddenImgCards
        }`}
        xs={4}
        key={index}
      >
        <div
          className={`${styles.AnimationConicBorder}`}
          data-speed={element.angleSpeed}
        >
          <Image
            style={{ borderRadius: '5px' }}
            src={element.imgSrc}
            className={'w-100'}
          />
        </div>
      </Col>
    );
  });
};
