import * as React from 'react';
import { INewComerWeekProduct } from '../../Products/ProductsSlideshow/ProductsSlideshow';
// @ts-ignore
import styles from './NcwProductList.module.scss?module';
import { formatCurrencyEur } from '../../../../Helper/formatter';
import { postRequest } from '../../../../Helper/ApiClient';
import ButtonMutation from '../../../../Abstracts/ButtonMutation/ButtonMutation';
import NcwCircleXButton from '../../../../Abstracts/NcwCircleXButton/NcwCircleXButton';
import NcwProductSize from './NcwProductSize/NcwProductSize';
import { Col, Container, Row } from 'react-bootstrap';

export interface NcwBasketProduct {
  product_attribute_id: number;
  quantity: number;
  price: number;
}

export default function NcwProductList({
  ncwProduct,
  handleCloseActiveProduct,
  onAddToBasketSuccess,
}: {
  ncwProduct: INewComerWeekProduct;
  handleCloseActiveProduct?: () => void;
  onAddToBasketSuccess?: () => void;
}) {
  const [paPositions, setPaPositions] = React.useState<NcwBasketProduct[]>([]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pa_id = Number(e.currentTarget.dataset.product_attribute_id);
    const qty = Number(e.currentTarget.value);
    const price = Number(e.currentTarget.dataset.price);
    if (qty > 0) {
      setPositionByPaIdQtyPrice(pa_id, qty, price);
    } else {
      removePositionByPaId(pa_id);
    }
  };

  const setPositionByPaIdQtyPrice = (_product_attribute_id, _qty, _price) => {
    const _paPositions = [...paPositions];
    const foundPaPositionProductAttribute = _paPositions.find(
      (_pa) => _pa.product_attribute_id === _product_attribute_id
    );
    if (foundPaPositionProductAttribute) {
      foundPaPositionProductAttribute.quantity = _qty;
      setPaPositions(_paPositions);
    } else {
      const currentPaPositionForBasket = [...paPositions];
      currentPaPositionForBasket.push({
        product_attribute_id: _product_attribute_id,
        quantity: _qty,
        price: _price,
      });
      setPaPositions(currentPaPositionForBasket);
    }
  };

  const removePositionByPaId = (productId: number) => {
    setPaPositions((prevPositions) =>
      prevPositions.filter(
        (position) => position.product_attribute_id !== productId
      )
    );
  };

  const getUnits = () => {
    const totalUnits = paPositions.reduce((acc, obj) => acc + obj.quantity, 0);
    return totalUnits ?? 0;
  };

  const getTotal = () => {
    const totalPrice = paPositions.reduce(
      (acc, obj) => acc + obj.price * obj.quantity,
      0
    );
    return formatCurrencyEur(totalPrice);
  };

  const addRefs = (element: HTMLInputElement | null) => {
    if (element) {
      const exists = refsArray.current.some((ref) => ref?.id === element.id);

      if (!exists) {
        refsArray.current.push(element);
      }
    }
  };

  const refsArray = React.useRef<(HTMLInputElement | null)[]>([]);

  const addToBasketMutation = postRequest(
    '/api/onlineshop/newcomer-week/basket/positions'
  );
  const handleClickAddAllToBasket = () => {
    let formData = new FormData();
    formData.append('positionsJson', JSON.stringify(paPositions));
    addToBasketMutation.mutate(formData, {
      onSuccess: () => {
        setTimeout(() => {
          setPaPositions([]);
          refsArray.current?.forEach((ref) => {
            if (ref) {
              ref.value = '';
            }
          });
          if (onAddToBasketSuccess) {
            onAddToBasketSuccess();
          }
        }, 1000);
      },
      onError: (error) => {
        // alert(error.message);
      },
    });
  };

  return (
    <div className="w-100 d-flex flex-column justify-content-end position-relative gap-2">
      {handleCloseActiveProduct && (
        <div
          style={{
            width: '100%',
            minHeight: '100px',
          }}
        >
          <NcwCircleXButton
            color={ncwProduct.colorText || 'white'}
            onClick={handleCloseActiveProduct}
          />
        </div>
      )}

      <Container
        fluid
        className={'py-5 CustomScrollbar'}
        style={{
          color: ncwProduct.colorText || 'white',
          maxHeight: '100%', // todo @mohamad
          overflowY: 'auto', // todo @mohamad
        }}
      >
        {ncwProduct.product.productSizes.map((productSizeData) => (
          <React.Fragment key={productSizeData.erp_id}>
            <NcwProductSize
              componentRef={(el) => addRefs(el)}
              onChange={onChange}
              productSizeData={productSizeData}
            />
            <Row>
              <Col className={'d-flex justify-content-between pt-4'}>
                <span
                  style={{ wordBreak: 'break-word' }}
                  className="me-md-0 me-2 pt-2 text-white"
                >
                  {paPositions.length > 0 ? `Units: ${getUnits()}` : ''}
                </span>
                <span
                  style={{ wordBreak: 'break-word' }}
                  className="me-md-0 me-2 pt-2 text-white"
                >
                  {paPositions.length > 0 ? `Total: ${getTotal()}` : ''}
                </span>

                <ButtonMutation
                  disabled={getUnits() === 0}
                  onClick={handleClickAddAllToBasket}
                  mutationResult={addToBasketMutation}
                  // color="gray"
                  style={{
                    width: '200px',
                    minWidth: '200px',
                    height: '50px',
                    border: 'none',
                    fontWeight: '800 important',
                    display: 'flex',
                    padding: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '1rem',
                    // color: '#000',
                  }}
                >
                  Add To Basket
                </ButtonMutation>
              </Col>
            </Row>
            <Row className={styles.SizeDividerRow}>
              <Col className={'py-3'}>
                <hr />
              </Col>
            </Row>
          </React.Fragment>
        ))}
        {/* <Row>
          <Col className={'d-flex justify-content-between'}>
            <span className="me-md-0 me-2 pt-2">
              {paPositions.length > 0 ? `Units: ${getUnits()}` : ''}
            </span>
            <span className="me-md-0 me-2 pt-2">
              {paPositions.length > 0 ? `Total: ${getTotal()}` : ''}
            </span>

            <ButtonMutation
              disabled={getUnits() === 0}
              onClick={handleClickAddAllToBasket}
              mutationResult={addToBasketMutation}
              // color="gray"
              style={{
                width: '200px',
                height: '50px',
                border: 'none',
                fontWeight: '800 important',
                display: 'flex',
                padding: 0,
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1rem',
                // color: '#000',
              }}
            >
              Add To Basket
            </ButtonMutation>
          </Col>
        </Row> */}
      </Container>
      {/*<Container>*/}
      {/*  <Row>*/}
      {/*    <Col className={'d-flex justify-content-between'}>*/}
      {/*      <span*/}
      {/*        style={{ wordBreak: 'break-word' }}*/}
      {/*        className="me-md-0 me-2 pt-2 text-white"*/}
      {/*      >*/}
      {/*        {paPositions.length > 0 ? `Units: ${getUnits()}` : ''}*/}
      {/*      </span>*/}
      {/*      <span*/}
      {/*        style={{ wordBreak: 'break-word' }}*/}
      {/*        className="me-md-0 me-2 pt-2 text-white"*/}
      {/*      >*/}
      {/*        {paPositions.length > 0 ? `Total: ${getTotal()}` : ''}*/}
      {/*      </span>*/}

      {/*      <ButtonMutation*/}
      {/*        disabled={getUnits() === 0}*/}
      {/*        onClick={handleClickAddAllToBasket}*/}
      {/*        mutationResult={addToBasketMutation}*/}
      {/*        // color="gray"*/}
      {/*        style={{*/}
      {/*          width: '200px',*/}
      {/*          minWidth: '200px',*/}
      {/*          height: '50px',*/}
      {/*          border: 'none',*/}
      {/*          fontWeight: '800 important',*/}
      {/*          display: 'flex',*/}
      {/*          padding: 0,*/}
      {/*          justifyContent: 'center',*/}
      {/*          alignItems: 'center',*/}
      {/*          fontSize: '1rem',*/}
      {/*          // color: '#000',*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        Add To Basket*/}
      {/*      </ButtonMutation>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</Container>*/}
      <div aria-label="spacer" />
    </div>
  );
}
