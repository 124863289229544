import * as React from 'react';
import ButtonMutation from '../../../../Abstracts/ButtonMutation/ButtonMutation';
import usePostMutation from '../../../../Helper/usePostMutation';
import { NcwBasket } from '../../../../../Interface/Ncw';

export default function PlaceOrderButton({
  basket,
  onPlaceOrderSuccess,
  disabled,
}: {
  basket: NcwBasket;
  onPlaceOrderSuccess: () => void;
  disabled?: boolean;
}) {
  const placeOrderMutation = usePostMutation<{is_backorder_confirmation: boolean}>(
    `/api/onlineshop/newcomer-week/basket/place-order`
  );
  React.useEffect(() => {
    if (placeOrderMutation.isSuccess) {
      onPlaceOrderSuccess();
    }
  }, [placeOrderMutation.isSuccess]);
  return (
    <ButtonMutation
      mutationResult={placeOrderMutation}
      onClick={placeOrderMutation.onClickHandler}
      disabled={disabled}
    >
      {basket.is_backorder_confirmation ? (
        <>Confirm Backorder</>
      ) : (
        <>Place Order</>
      )}
    </ButtonMutation>
  );
}
