import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Marquee from 'react-fast-marquee';

const NcwMarquee = ({ fluid = false }: { fluid?: boolean }) => {
  return (
    <Container
      style={{ maxWidth: '100vw', overflow: 'hidden' }}
      fluid={fluid}
      className={'g-0'}
    >
      <Row>
        <Col>
          <Marquee
            className={'fs-5 fst-italic text-uppercase py-1'}
            style={{
              backgroundColor: '#19eb19',
              color: '#000000',
              fontWeight: '900',
              maxWidth: '100vw',
            }}
            autoFill={true}
          >
            <div className="d-flex gap-4">
              <span>Newcomer Week</span>
              <span>-</span>
              <span>Join now</span>
              <span>-</span>
              <div aria-label="spacer" />
            </div>
          </Marquee>
        </Col>
      </Row>
    </Container>
  );
};

export default NcwMarquee;
