import * as React from 'react';
import { INewComerWeekBrands } from '../../../../BrandsSlideShow/BrandsSlideShow';
import ButtonMutation from '../../../../../../../Abstracts/ButtonMutation/ButtonMutation';
import { formatCurrencyEur } from '../../../../../../../Helper/formatter';
import { postRequest } from '../../../../../../../Helper/ApiClient';
import { NcwBasketProduct } from '../../../../../Abstracts/NcwProductList/NcwProductList';
import CategoryProducts from './CategoryProducts/CategoryProducts'; // import Button from '../../../../../../../Abstracts/Button/Button';
//@ts-ignore
import styles from './NcwBrandCategories.module.scss?module';
import { useNcw } from '../../../../../Context/NcwContext';
import { Col, Row } from 'react-bootstrap';
import NcwProductSize from '../../../../../Abstracts/NcwProductList/NcwProductSize/NcwProductSize';
import { useBrowserCheck } from '../../../../../../../Hooks/useBrowserCheck';

export default function NcwBrandCategories({
  ncwBrand,
}: {
  ncwBrand: INewComerWeekBrands;
}) {
  const [paPositions, setPaPositions] = React.useState<NcwBasketProduct[]>([]);
  const categorySectionRefs = React.useRef<{
    [key: string]: HTMLDivElement | null;
  }>({});
  const { checkBrowser } = useBrowserCheck();

  const browserSpecificStyles: React.CSSProperties = checkBrowser([
    'isSafariIOS',
  ])
    ? {
        height: '50px',
      }
    : {
        bottom: '30px',
      };

  const scrollToSection = (categoryId: string) => {
    const sectionRef = categorySectionRefs.current[categoryId];
    if (sectionRef) {
      sectionRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pa_id = Number(e.currentTarget.dataset.product_attribute_id);
    const qty = Number(e.currentTarget.value);
    const price = Number(e.currentTarget.dataset.price);
    if (qty > 0) {
      setPositionByPaIdQtyPrice(pa_id, qty, price);
    } else {
      removePositionByPaId(pa_id);
    }
  };

  const setPositionByPaIdQtyPrice = (_product_attribute_id, _qty, _price) => {
    const _paPositions = [...paPositions];
    const foundPaPositionProductAttribute = _paPositions.find(
      (_pa) => _pa.product_attribute_id === _product_attribute_id
    );
    if (foundPaPositionProductAttribute) {
      foundPaPositionProductAttribute.quantity = _qty;
      setPaPositions(_paPositions);
    } else {
      const currentPaPositionForBasket = [...paPositions];
      currentPaPositionForBasket.push({
        product_attribute_id: _product_attribute_id,
        quantity: _qty,
        price: _price,
      });
      setPaPositions(currentPaPositionForBasket);
    }
  };

  const removePositionByPaId = (productId: number) => {
    setPaPositions((prevPositions) =>
      prevPositions.filter(
        (position) => position.product_attribute_id !== productId
      )
    );
  };

  const getUnits = () => {
    const totalUnits = paPositions.reduce((acc, obj) => acc + obj.quantity, 0);
    return totalUnits ?? 0;
  };

  const getTotal = () => {
    const totalPrice = paPositions.reduce(
      (acc, obj) => acc + obj.price * obj.quantity,
      0
    );
    return formatCurrencyEur(totalPrice);
  };

  const addRefs = (element: HTMLInputElement | null) => {
    if (element) {
      const exists = refsArray.current.some((ref) => ref?.id === element.id);

      if (!exists) {
        refsArray.current.push(element);
      }
    }
  };

  const ncwContext = useNcw();
  const refsArray = React.useRef<(HTMLInputElement | null)[]>([]);

  const addToBasketMutation = postRequest(
    '/api/onlineshop/newcomer-week/basket/positions'
  );
  const handleClickAddAllToBasket = () => {
    let formData = new FormData();
    formData.append('positionsJson', JSON.stringify(paPositions));
    addToBasketMutation.mutate(formData, {
      onSuccess: () => {
        setTimeout(() => {
          setPaPositions([]);
          refsArray.current?.forEach((ref) => {
            if (ref) {
              ref.value = '';
            }
          });
          ncwContext.refetchNcwBasketHeader();
        }, 1000);
      },
    });
  };

  const categories = ncwBrand.categories;
  return (
    <>
      <div className={`${styles.CategoriesContainer}`}>
        {Object.keys(categories).map((categoryId) => {
          return (
            <button
              key={categoryId}
              onClick={() => scrollToSection(categoryId)}
              className={styles.CategoryItemBox}
            >
              {categories[categoryId].name}
            </button>
          );
        })}
      </div>
      <div className="d-flex overflow-hidden gap-md-4">
        <div aria-label="spacer" />
        <div className="d-flex flex-column">
          <div aria-label="spacer" />
          {Object.keys(categories).map((categoryId) => {
            let categoryProducts = categories[categoryId];
            let componentRef = (el) => addRefs(el);
            return (
              <div
                key={categoryId}
                ref={(el) => (categorySectionRefs.current[categoryId] = el)}
              >
                {/* * /}
                <CategoryProducts
                  categoryProducts={categories[categoryId]}
                  componentRef={(el) => addRefs(el)}
                  onChange={onChange}
                />
                {/* */}
                <div className={'pt-5'}>
                  <h2>{categoryProducts.name}</h2>
                  <hr />
                </div>
                <div className="d-flex flex-column gap-4">
                  {categoryProducts.productSizes.map((productSizeData) => (
                    <React.Fragment key={productSizeData.id}>
                      <NcwProductSize
                        isBrand
                        productSizeData={productSizeData}
                        componentRef={componentRef}
                        onChange={onChange}
                      />
                      <div className="w-100 f-flex">
                        <ButtonMutation
                          disabled={getUnits() === 0}
                          onClick={handleClickAddAllToBasket}
                          mutationResult={addToBasketMutation}
                          // color="gray"
                          style={{
                            width: '100%',
                            height: '50px',
                            border: 'none',
                            fontWeight: '800 important',
                            display: 'flex',
                            padding: 0,
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '1rem',
                            // color: '#000',
                          }}
                        >
                          Add To Basket
                        </ButtonMutation>
                      </div>
                      <Row className={styles.SizeDividerRow}>
                        <Col>
                          <hr />
                        </Col>
                      </Row>
                    </React.Fragment>
                  ))}
                </div>
                {/* */}
              </div>
            );
          })}
        </div>
        <div aria-label="spacer" />
      </div>
      <div
        className="sticky-bottom bg-black p-0 d-flex flex-column gap-2"
        style={{
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBlock: '1rem',
          color: ncwBrand.colorText || 'white',
        }}
      >
        <div aria-label="spacer" />
        <div
          className="d-flex justify-content-end w-100"
          style={{ fontSize: '1.2rem', gap: '1rem', paddingInline: '1rem' }}
        >
          {paPositions.length > 0 && (
            <span className="d-flex" style={{ ...browserSpecificStyles }}>
              UNITS: {getUnits()}
            </span>
          )}

          <span className="d-flex">
            {paPositions.length > 0 ? `TOTAL: ${getTotal()}` : ''}
          </span>
        </div>
        {/* <div className="w-100 f-flex px-md-3">
          <ButtonMutation
            disabled={getUnits() === 0}
            onClick={handleClickAddAllToBasket}
            mutationResult={addToBasketMutation}
            // color="gray"
            style={{
              width: '100%',
              height: '50px',
              border: 'none',
              fontWeight: '800 important',
              display: 'flex',
              padding: 0,
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '1rem',
              // color: '#000',
            }}
          >
            Add To Basket
          </ButtonMutation>
        </div> */}
        <div aria-label="spacer" />
      </div>
    </>
  );
}
