import React, { useState, useEffect } from 'react';

// @ts-ignore
import styles from './Brands.module.scss?module';
import useViewport from '../../../Hooks/useViewport';
import { getRequest } from '../../../Helper/ApiClient';
import { INewComerWeekBrands } from './BrandsSlideShow/BrandsSlideShow';
import { useNavigate, useParams } from 'react-router-dom';
import useNewcomerWeekHTMLConfig from '../../../Hooks/useNewcomerWeekHTMLConfig';
import BrandPagesMobile from './BrandPages/BrandPagesMobile/BrandPagesMobile';
import BrandPagesDesktop from './BrandPages/BrandPagesDesktop/BrandPagesDesktop';

export default function Brands() {
  const viewport = useViewport(1200);
  const ncwBrandsRequest = getRequest<INewComerWeekBrands[]>(
    `/api/onlineshop/newcomer-week/brands`
  );
  const ncwBrands = ncwBrandsRequest.data ?? [];

  const { bid } = useParams();
  const navigate = useNavigate();
  const [activeBrand, setActiveBrand] = useState(0);
  const [isfirstBrand, setIsFirstBrand] = useState(false);
  const [isLastBrand, setIsLastBrand] = useState(false);
  useNewcomerWeekHTMLConfig(styles.NewcomerWeekHtml);

  useEffect(() => {
    if (ncwBrandsRequest.data) {
      if (!bid) {
        if (viewport == 'mobile') {
          setIsFirstBrand(true);
          navigate(
            `/newcomer-week/brands/${
              ncwBrandsRequest.data && ncwBrandsRequest.data[0].erp_id
            }`
          );
        } else if (viewport == 'desktop') {
          navigate(
            `/newcomer-week/brands/${
              ncwBrandsRequest.data && ncwBrandsRequest.data[1].erp_id
            }`
          );
        }

        return;
      }

      const index = ncwBrandsRequest.data.findIndex(
        (product) => product.erp_id === Number(bid)
      );
      if (index !== -1) {
        setActiveBrand(index);
        setIsLastBrand(index === ncwBrands.length - 1);
        setIsFirstBrand(index === 0);
      }
    }
  }, [bid, ncwBrandsRequest.data, viewport]);

  function slideToNextBrand() {
    if (ncwBrands && activeBrand < ncwBrands.length - 1) {
      const nextIndex = activeBrand + 1;
      const nextProduct = ncwBrands[nextIndex];
      navigate(`/newcomer-week/brands/${nextProduct.erp_id}`);
    }
  }
  function slideToPrevBrand() {
    if (activeBrand > 0 && ncwBrands) {
      const prevIndex = activeBrand - 1;
      const prevProduct = ncwBrands[prevIndex];
      navigate(`/newcomer-week/brands/${prevProduct.erp_id}`);
    }
  }

  return (
    <>
      {viewport == 'desktop' && (
        <BrandPagesDesktop
          slideToPrevBrand={slideToPrevBrand}
          slideToNextBrand={slideToNextBrand}
          ncwBrands={ncwBrandsRequest.data || []}
          activeIndex={activeBrand}
        />
      )}
      {viewport == 'mobile' && (
        <BrandPagesMobile
          ncwBrands={ncwBrandsRequest.data || []}
          activeBrand={activeBrand}
          slideToPrevBrand={slideToPrevBrand}
          slideToNextBrand={slideToNextBrand}
          isfirstBrand={isfirstBrand}
          isLastBrand={isLastBrand}
        />
      )}
    </>
  );
}
