import React, { useEffect, useRef } from 'react';

//@ts-ignore
import styles from './BrandPagesMobile.module.scss?module';
import { Col, Row, Container } from 'react-bootstrap';
import { INewComerWeekBrands } from '../../BrandsSlideShow/BrandsSlideShow';
import Paginator from '../../../Paginator/Paginator';
import { useSliderGesture } from '../../../../../Hooks/useSliderGesture';
import SnapSection from '../../../Abstracts/SnapSection/SnapSection';
import NcwBrandCategories from '../components/BrandProductList/NcwBrandCategories/NcwBrandCategories';
import BrandPresentation from '../components/BrandPresentation/BrandPresentation';

type BrandPagesMobileProps = {
  ncwBrands: INewComerWeekBrands[];
  activeBrand: number;
  slideToPrevBrand: () => void;
  slideToNextBrand: () => void;
  isfirstBrand: boolean;
  isLastBrand: boolean;
};

const BrandPages = ({
  ncwBrands,
  activeBrand,
  slideToPrevBrand,
  slideToNextBrand,
  isfirstBrand,
  isLastBrand,
}: BrandPagesMobileProps) => {
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

  const sliderRef = useSliderGesture({
    onSwipeLeft: slideToPrevBrand,
    onSwipeRight: slideToNextBrand,
  });

  useEffect(() => {
    videoRefs.current = videoRefs.current.slice(0, ncwBrands.length);

    videoRefs.current.forEach((video, index) => {
      if (video) {
        video.currentTime = 0;
        if (index === activeBrand) {
          video.play();
        }
      }
    });
  }, [activeBrand, ncwBrands]);

  return (
    <>
      <div
        style={{
          overscrollBehavior: 'none',
          maxWidth: '100vw',
          contain: 'paint',
        }}
      >
        <div
          ref={sliderRef}
          className="d-flex"
          style={{
            transform: `translate3d(-${activeBrand}00vw, 0,0)`,
            transition: 'all 700ms ease-in-out',
          }}
        >
          {ncwBrands.map((brand, index) => {
            const isActiveBrand = activeBrand === index;
            return (
              <Container
                key={brand.erp_id}
                fluid
                style={{
                  minWidth: '100vw',
                  opacity: isActiveBrand ? 1 : 0.4,
                  transition: 'opacity 700ms ease-in-out',
                  backgroundColor: brand.colorBackground
                    ? brand.colorBackground
                    : undefined,
                  color: brand.colorText ? brand.colorText : undefined,
                  zIndex: 0,
                }}
                className="d-flex flex-column justify-content-around flex-grow-1"
              >
                <Row
                  className="flex-grow-1"
                  style={{
                    minHeight: 'calc(100vh - 112px)',
                    scrollSnapAlign: 'start',
                    scrollSnapStop: 'always',
                  }}
                >
                  <Col className="p-0">
                    <div
                      className={styles.VideoContainer}
                      style={{ minHeight: 'calc(112px - 100vh)', zIndex: 1 }}
                    >
                      <video
                        ref={(element) => (videoRefs.current[index] = element)}
                        className={styles.BackgroundVideo}
                        src={brand.brand_video_url}
                        autoPlay={isActiveBrand}
                        controls={false}
                        muted
                        loop
                        playsInline
                      />
                    </div>
                  </Col>
                </Row>
                <SnapSection scrollToTop activeItem={isActiveBrand}>
                  <BrandPresentation ncwBrand={brand} />
                  <NcwBrandCategories ncwBrand={brand} />
                </SnapSection>
                <Row
                  style={{
                    minHeight: '56px',
                    scrollSnapAlign: 'end',
                    scrollSnapStop: 'always',
                  }}
                ></Row>
              </Container>
            );
          })}
        </div>
      </div>
      <Paginator
        isFirstItem={isfirstBrand}
        isLastItem={isLastBrand}
        requestData={ncwBrands}
        slideToNextItem={slideToNextBrand}
        slideToPrevItem={slideToPrevBrand}
      />
    </>
  );
};

export default BrandPages;
