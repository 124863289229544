import React from 'react';

// @ts-ignore
import styles from './Footer.module.scss?module';
import { Col, Container, Row } from 'react-bootstrap';
import cn from 'classnames';
import NavigationButton from '../Components/NavigationButton/NavigationButton';
import { faBagShopping, faHeart } from '@fortawesome/pro-solid-svg-icons';
import Link from '../../../Abstracts/Link/Link';

export default function Footer() {
  return (
    <Container fluid className={cn(styles.Footer, 'd-lg-none', 'd-none')}>
      <Row>
        <Col style={{ height: '50px' }}>&nbsp;Footer save space for arrows</Col>
      </Row>
    </Container>
  );
}
