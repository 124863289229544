import * as React from 'react';
import { INewComerWeekBrands } from '../../../BrandsSlideShow/BrandsSlideShow';
import { Col, Container, Image, Row } from 'react-bootstrap';

export default function BrandPresentation({
  ncwBrand,
}: {
  ncwBrand: INewComerWeekBrands;
}) {
  return (
    <Container fluid>
      <Row>
        <Col className={'text-center py-5'}>
          <Image src={ncwBrand.brand_logo_url} style={{ maxWidth: '100%' }} />
        </Col>
      </Row>
      <Row>
        <Col className={'text-center'}>
          <p>{ncwBrand.brand_description}</p>
        </Col>
      </Row>
    </Container>
  );
}
