import * as React from 'react';
import Link from '../../../../Abstracts/Link/Link';
import { Modal } from 'react-bootstrap';
import ButtonMutation from '../../../../Abstracts/ButtonMutation/ButtonMutation';
import useDeleteMutation from '../../../../Helper/useDeleteMutation';
export default function ClearBasket({
  onClearBasketSuccess,
}: {
  onClearBasketSuccess: () => void;
}) {
  const [popupShow, setPopupShow] = React.useState(false);
  const deleteMutation = useDeleteMutation('/api/onlineshop/newcomer-week/basket/basket-empty');

  React.useEffect(() => {
    if (deleteMutation.isSuccess) {
      onClearBasketSuccess();
      setPopupShow(false);
    }
  }, [deleteMutation.isSuccess]);

  return (
    <>
      <Link
        onClick={() => {
          setPopupShow(true);
        }}
      >
        Clear Basket
      </Link>
      <Modal show={popupShow} onHide={() => setPopupShow(false)}>
        <Modal.Body>
          <h1>Please confirm</h1>
          <p className={'py-4'}>
            Are you sure you want to delete all positions in your basket?
          </p>
          <ButtonMutation
            mutationResult={deleteMutation}
            onClick={deleteMutation.onClickHandler}
          >
            Clear Basket
          </ButtonMutation>
        </Modal.Body>
      </Modal>
    </>
  );
}
