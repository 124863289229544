import * as React from 'react';
import usePostMutation from '../../../../Helper/usePostMutation';
import { NcwProductAttributeQty } from '../FlavourList';
import ButtonMutation from '../../../../Abstracts/ButtonMutation/ButtonMutation';

export default function AddAllToBasketButton({
  ncwProductAttributeQty,
  onSuccess,
}: {
  ncwProductAttributeQty: NcwProductAttributeQty;
  onSuccess: () => void;
}) {
  const ncwBasketQtyMutation = usePostMutation('/api/onlineshop/newcomer-week/basket/positions');

  const onClick = () => {
    const data: { [key: string]: number | string }[] = [];
    Object.keys(ncwProductAttributeQty).map((id) => {
      data.push({
        ncw_product_attribute_id: id,
        quantity: ncwProductAttributeQty[id].qty,
      });
    });
    const formData = new FormData();
    formData.set("positionsJson", JSON.stringify(data));
    ncwBasketQtyMutation.mutate(formData, {
      onSuccess: onSuccess,
      onError: (error) => {
        // alert(error.message);
      },
    });
  };
  return (
    <ButtonMutation onClick={onClick} mutationResult={ncwBasketQtyMutation}>
      Add all to basket
    </ButtonMutation>
  );
}
