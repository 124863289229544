import React from "react";
import { Image } from "react-bootstrap";
import { IProductListProduct } from "../../../../ProductList";
// @ts-ignore
import style from "./Overlay.module.scss?module";

const Overlay = ({ is_new, image_overlay_uri, name }: IProductListProduct) => {
  return (
    <div>
      {is_new && <div className={style.new}>NEW!</div>}
      {image_overlay_uri && (
        <Image
          fluid
          className={style.overlayImage}
          src={image_overlay_uri}
          alt={name}
        />
      )}
    </div>
  );
};

export default Overlay;
