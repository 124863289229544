import React from 'react';
import { IProductListProduct } from '../../../ProductList';
import { formatCurrencyEur } from '../../../../../Helper/formatter';

const ProductDetails = ({
  category,
  unit_value,
  unit,
  vpe,
  is_deposit,
  deposit_price,
  msrp,
}: IProductListProduct) => {
  return (
    <div>
      <div className="small">
        <span className="fw-bold">Category: </span>
        <span>{category.name}</span>
      </div>
      <div className="small">
        <span className="fw-bold">Size: </span>
        <span>{`${unit_value} ${unit}`}</span>
      </div>
      <div className="small">
        <span className="fw-bold">Units per case: </span>
        <span>{vpe}</span>
      </div>
      {msrp > 0 &&
        <div className="small">
            <span className="fw-bold">MSRP: </span>
            <span>{formatCurrencyEur(msrp)}</span>
        </div>
      }
      {is_deposit && (
        <div className="small">
          <span className="fw-bold">Deposit: </span>
          <span>{formatCurrencyEur(deposit_price)}</span>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
