import * as React from 'react';
import { OrderInterface } from '../../../../Interface/Order';
import { Col, Collapse, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faDownload,
} from '@fortawesome/pro-solid-svg-icons';
import Addresses from '../Addresses/Addresses';
import OrderPositions from '../OrderPositions/OrderPositions';
import Summary from '../Summary/Summary';
export default function MinimalOrderDetails({
  order,
}: {
  order: OrderInterface;
}) {
  const [show, setShow] = React.useState(false);
  return (
    <>
      <Row className="pt-4">
        <Col>
          <div
            role="button"
            className="d-flex justify-content-between"
            onClick={() => setShow(!show)}
          >
            <Table className="d-inline" borderless>
              <tbody>
                <tr>
                  <td className="fw-bold p-0">Order date</td>
                  <td className="ps-4 p-0">{order.order_date}</td>
                </tr>
                <tr>
                  <td className="fw-bold p-0">Order number</td>
                  <td className="ps-4 p-0">{order.order_id}</td>
                </tr>
              </tbody>
            </Table>
            <FontAwesomeIcon icon={show ? faChevronDown : faChevronUp} />
          </div>
          <Collapse in={show}>
            <div id="collapse-order-details">
              <Table className="d-inline" borderless>
                <tbody>
                  <tr>
                    <td className="fw-bold p-0">Reference number</td>
                    <td className="ps-4 p-0">
                      {order.external_reference &&
                      order.external_reference.length > 1
                        ? order.external_reference
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold p-0">Carrier</td>
                    <td className="ps-4 p-0">
                      {order.carrier ? order.carrier.name : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold p-0">Invoice</td>
                    <td className="ps-4 p-0">
                      {order.order_shipped ? (
                        <a
                          className="inherit"
                          href={'/orders/invoicePdf/' + order.order_id}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Addresses order={order} />
              <div className="pt-4">
                <OrderPositions order={order} />
              </div>
              <Summary showTotalQuantity order={order} />
            </div>
          </Collapse>
          {!show && <Summary showTotalQuantity order={order} totalOnly />}
        </Col>
      </Row>
    </>
  );
}
