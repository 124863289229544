import React from 'react';
//@ts-ignore
import styles from './NcwCircleXButton.module.scss?module';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

interface NcwCircleXButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  onClick: () => void;
  color?: string;
}

const NcwCircleXButton: React.FC<NcwCircleXButtonProps> = ({
  onClick,
  color = 'white',
  ...props
}) => {
  return (
    <div
      {...props}
      style={
        props.style
          ? props.style
          : {
              zIndex: 1,
              color: color,
              border: `3px solid ${color}`,
            }
      }
      className={props.className ? props.className : styles.CloseBtn}
      onClick={onClick}
    >
      <FontAwesomeIcon size="2xl" icon={faXmark} />
    </div>
  );
};

export default NcwCircleXButton;
