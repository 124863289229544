import React from 'react';
import {
  type FontAwesomeIconProps,
  FontAwesomeIcon,
} from '@fortawesome/react-fontawesome';
import { type IconDefinition } from '@fortawesome/pro-solid-svg-icons';

//@ts-ignore
import styles from './NavigationButton.module.scss?module';
import { Badge } from 'react-bootstrap';

const NavigationButton = ({
  size,
  icon,
  badge,
}: {
  size?: FontAwesomeIconProps['size'] | undefined;
  icon: IconDefinition;
  badge?: string;
}) => {
  return (
    <button className={styles.NavigationButton}>
      <FontAwesomeIcon
        size={size ? size : 'lg'}
        className={styles.NavigationIcons}
        icon={icon}
      />
      {badge && (
        <Badge
          style={{ fontSize: '9px', padding: '3px 5px', top: '5px' }}
          pill
          className="position-absolute start-100 translate-middle"
          bg="warning"
        >
          {badge}
        </Badge>
      )}
    </button>
  );
};

export default NavigationButton;
