import * as React from 'react';
import { INewComerWeekProduct } from '../../../../../ProductsSlideshow/ProductsSlideshow';
import { Image } from 'react-bootstrap';
export default function ProductInformationContent({
  ncwProduct,
  isDesktop,
}: {
  ncwProduct: INewComerWeekProduct;
  isDesktop?: boolean;
}) {
  return (
    <>
      {isDesktop && (
        <>
          <h2 className="m-0">{ncwProduct.product.brand.name}</h2>
          <h5 className="m-0">{ncwProduct.product.name}</h5>
        </>
      )}
      <Image className={'w-100'} src={ncwProduct.product.image_nf_url} />
      <p className="mt-2">{ncwProduct.description}</p>
    </>
  );
}
