import React from 'react';
import NavigationButton from '../Components/NavigationButton/NavigationButton';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';

const Paginator = ({
  isFirstItem,
  isLastItem,
  requestData,
  slideToNextItem,
  slideToPrevItem,
}) => {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        textAlign: 'center',
        width: '100%',
        zIndex: 10,
        height: '56px',
      }}
      className="d-flex gap-6 justify-content-center bg-primary py-2"
    >
      <div
        style={{
          opacity: isFirstItem ? 0.5 : 1,
        }}
        onClick={() => {
          requestData && slideToPrevItem();
        }}
      >
        <NavigationButton size="xl" icon={faArrowLeft} />
      </div>

      <div
        style={{ opacity: isLastItem ? 0.5 : 1 }}
        onClick={() => {
          requestData && slideToNextItem();
        }}
      >
        <NavigationButton size="xl" icon={faArrowRight} />
      </div>
    </div>
  );
};

export default Paginator;
