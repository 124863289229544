import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

//@ts-ignore
import styles from './SlideShowSlider.module.scss?module';
import ProductSlidePageImage from './VerticalSections/ProductSlidePageImage/ProductSlidePageImage';
import ProductSlidePageInformation from './VerticalSections/ProductSlidePageInformation/ProductSlidePageInformation';
import ProductSlidePageOrderList from './VerticalSections/ProductSlidePageOrderList/ProductSlidePageOrderList';
import { INewComerWeekProduct } from '../../ProductsSlideshow/ProductsSlideshow';
import ProductSlidePageProducts from './VerticalSections/ProductSlidePageProducts/ProductSlidePageProducts';

const SlideShowSlider = ({
  index,
  activeIndex,
  ncwProduct,
}: {
  index: number;
  activeIndex: number;
  ncwProduct: INewComerWeekProduct;
}) => {
  const product = ncwProduct.product;

  return (
    <>
      <Container
        fluid
        className={`${styles.Container} ${
          activeIndex == index && styles.ActiveProduct
        }`}
        style={{
          backgroundColor: ncwProduct.colorBackground || 'black',
          zIndex: 0,
          color: ncwProduct.colorText || 'white',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundImage: ncwProduct.backgroundUrl
              ? `url("${ncwProduct.backgroundUrl}")`
              : undefined,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: `center`,
            width: '100%',
            height: activeIndex == index ? 'calc(100vh - 112px)' : '100vh',
            zIndex: -1,
            transition: 'height 700ms ease-in-out',
          }}
        />
        <ProductSlidePageImage ncwProduct={ncwProduct} />
        <ProductSlidePageProducts ncwProduct={ncwProduct} />
        <ProductSlidePageInformation ncwProduct={ncwProduct} />

        {/* Kleiner scroll bounce fix für Safari.*/}
        <Row
          style={{
            scrollSnapAlign: 'start',
            minHeight: '70px',
          }}
        ></Row>
      </Container>
    </>
  );
};

export default SlideShowSlider;
