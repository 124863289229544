import * as React from 'react';
import { OrderInterface } from '../../../../Interface/Order';
import { Table } from 'react-bootstrap';
import { formatCurrencyEur } from '../../../Helper/formatter';

export default function OrderPositions({ order }: { order: OrderInterface }) {
  return (
    <>
      <Table size="sm">
        <thead>
          <tr>
            <th>Brand</th>
            <th>Product</th>
            <th>Flavour</th>
            <th className="text-end">Price</th>
            <th className="text-end">Quantity</th>
            <th className="text-end">Total</th>
          </tr>
        </thead>
        <tbody>
          {order.order_positions &&
            order.order_positions.map((position, index) => {
              return (
                <tr key={index}>
                  <td>{position?.brand_name}</td>
                  <td>{position?.product_name}</td>
                  <td>{position?.product_attribute_name}</td>
                  <td className="text-end">
                    {formatCurrencyEur(position.single_price_exclusive_tax)}
                  </td>
                  <td className="text-end">{position.quantity}</td>
                  <td className="text-end">
                    {formatCurrencyEur(position.total_price_exclusive_tax)}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
}
