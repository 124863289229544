import React from "react";
import { Image } from "react-bootstrap";
import { IProductListProduct } from "../../../ProductList";
import Overlay from "./Overlay/Overlay";

const ProductImage = (product: IProductListProduct) => {
  return (
    <div className="position-relative">
      <Image fluid src={product.image_uri} className="h-auto" />
      {(product.is_new || product.image_overlay_uri) && (
        <Overlay {...product} />
      )}
    </div>
  );
};

export default ProductImage;
