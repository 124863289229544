import * as React from 'react';
import SnapSection from '../../../../../Abstracts/SnapSection/SnapSection';
import type { INewComerWeekProduct } from '../../../../ProductsSlideshow/ProductsSlideshow';
import { ViewportType } from '../../../../../../../Hooks/useViewport';
import { AnimationStepsTypes } from '../../../ProductPages';

//@ts-ignore
import style from './ProductSlidePageProducts.module.scss?module';
import NcwProductList from '../../../../../Abstracts/NcwProductList/NcwProductList';
import { useNcw } from '../../../../../Context/NcwContext';

export default function ProductSlidePageProducts({
  ncwProduct,
  viewport,
  activeProduct,
  animationSteps,
  setAnimationSteps,
  setProductDetailVisible,
}: {
  ncwProduct: INewComerWeekProduct;
  viewport?: ViewportType;
  activeProduct?: boolean;
  animationSteps?: AnimationStepsTypes;
  setAnimationSteps?: React.Dispatch<React.SetStateAction<AnimationStepsTypes>>;
  setProductDetailVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  function handleCloseActiveProduct() {
    if (setAnimationSteps && setProductDetailVisible && activeProduct) {
      // Start playing anmation to close
      setTimeout(() => {
        setAnimationSteps((prevVal) => ({
          ...prevVal,
          showProductSizeSection: false,
        }));
      }, 200);

      setTimeout(() => {
        setAnimationSteps((prevVal) => ({
          ...prevVal,
          showProductInformationSection: false,
        }));
      }, 500);

      setTimeout(() => {
        setAnimationSteps((prevVal) => ({
          ...prevVal,
          moveActiveProductToRight: false,
        }));
      }, 800);
      setTimeout(() => {
        setAnimationSteps((prevVal) => ({
          ...prevVal,
          hideNoneActiveProducts: false,
        }));
        setProductDetailVisible(false);
      }, 1000);
    }
  }

  const ncwContext = useNcw();

  return (
    <>
      {viewport == 'desktop' ? (
        <div
          className={style.PageProductContainer}
          style={{
            backgroundColor: ncwProduct.colorBackground || 'black',
            transform:
              activeProduct &&
              animationSteps &&
              animationSteps.showProductSizeSection
                ? 'translate3d(200%, 0, 0)'
                : 'translate3d(300%, 0, 0)',
            opacity:
              activeProduct &&
              animationSteps &&
              animationSteps.showProductSizeSection
                ? 1
                : 0,
          }}
        >
          <NcwProductList
            ncwProduct={ncwProduct}
            handleCloseActiveProduct={handleCloseActiveProduct}
            onAddToBasketSuccess={() => ncwContext.refetchNcwBasketHeader()}
          />
        </div>
      ) : (
        <SnapSection>
          <div className="h-100">
            <NcwProductList
              ncwProduct={ncwProduct}
              onAddToBasketSuccess={() => ncwContext.refetchNcwBasketHeader()}
            />
          </div>
        </SnapSection>
      )}
    </>
  );
}
