import React from 'react';

//@ts-ignore
import styles from './HomeLandingPage.module.scss?module';
import { Row, Col, Image, Container, Accordion } from 'react-bootstrap';
import Link from '../../../../Abstracts/Link/Link';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/pro-solid-svg-icons';

const IMAGE_SRC = 'https://files.naskorsports.com/shop/newcomer-week/';

const HomeLandingPage = () => {
  return (
    <>
      <Container fluid style={{ position: 'relative' }}>
        <div
          className={styles.NcwHomeBGImage}
          style={{
            backgroundImage: `url("${IMAGE_SRC}home/ncw-home-bg.jpg")`,
          }}
        ></div>
        <div className={styles.Overlay} aria-label="overlay" />
        <Row
          className="justify-content-md-center"
          style={{
            fontFamily: 'newcomer-week',
            position: 'relative',
            color: '#fff',
            overflowX: 'hidden', // protected small mobile screen from x-scroll when "discover" or date is too large
          }}
        >
          <span className={styles.NewcomerWeekDate}>16.-22.September</span>
          <Col md={8}>
            <div aria-label="spacer" style={{ height: '4rem' }} />
            <Row
              style={{
                position: 'relative',
              }}
            >
              <h1
                style={{
                  fontSize: 'clamp(5rem, 6rem + 2vw, 13rem)',
                  textTransform: 'uppercase',
                  lineHeight: '0.8',
                  fontStyle: 'normal',
                  letterSpacing: '2px',
                }}
              >
                Discover the <br />
                new!
              </h1>
            </Row>
            <Row>
              <Col className={styles.DefaultFont}>
                <h3 style={{ fontSize: '2rem', letterSpacing: '1px' }}>
                  Welcome to the NaskorSports <br /> Newcomer Week!
                </h3>
              </Col>
            </Row>
            <Row>
              <Col className={styles.DefaultFont}>
                <p>
                  Discover the latest brands and products in this exclusive
                  event.
                  <br />
                  This is your chance to explore fresh innovations at unbeatable
                  prices. Be among the first to access <br /> new arrivals and
                  take advantage of special offers designed just for you. Don’t
                  miss out on this <br /> unique opportunity to expand your
                  portfolio with exciting, new selections.
                </p>
                <p>Join us and elevate your business!</p>
              </Col>
            </Row>
            <div aria-label="spacer" style={{ height: '2rem' }} />
            <Row className="flex-column flex-lg-row d-flex gap-4 gap-lg-0">
              <Col lg={6}>
                <Link to={'/newcomer-week/brands'} opacityOnHover={false}>
                  <Image
                    className={styles.Images}
                    src={`${IMAGE_SRC}newBrandsDesktop.png`}
                  />
                </Link>
              </Col>
              <Col lg={6}>
                <Link to={'/newcomer-week/products'} opacityOnHover={false}>
                  <Image
                    className={styles.Images}
                    src={`${IMAGE_SRC}home/ncw-home-products.jpg`}
                  />
                </Link>
              </Col>
            </Row>
            <Col aria-label="spacer" style={{ height: '8rem' }} />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="justify-content-md-center">
          <Col md={8} className="gap-4 d-flex flex-column">
            <div className={'pt-5'}>
              <h3 className={styles.NcwFaqs}>FAQs</h3>
            </div>

            <Accordion defaultActiveKey="-1" className={styles.NcwAccordion}>
              <AccordionItem className={styles.AccordionItem} eventKey="0">
                <AccordionHeader>
                  How do I get the exclusive discounts?
                </AccordionHeader>
                <AccordionBody>
                  Take part in our Newcomer Week by clicking on one of the two
                  tiles above (New Brands or New Products). Click through and
                  complete your order in this event.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem eventKey="1">
                <AccordionHeader>
                  How long are the offers valid?
                </AccordionHeader>
                <AccordionBody>
                  The offers are only valid during Newcomer Week, from 16.09.24
                  to 22.09.24 at 11:59 pm.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem eventKey="2">
                <AccordionHeader>
                  How do I get the free Mystery Box?
                </AccordionHeader>
                <AccordionBody>
                  Complete an order in the Newcomer Week event and the box will
                  automatically be added to your order.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem eventKey="3">
                <AccordionHeader>
                  Which brands are taking part in the event?
                </AccordionHeader>
                <AccordionBody>
                  Participants in this year's Newcomer Week are: Applied
                  Nutrition, BuckedUp, CNP, Dorian Yates Nutrition, Foodspring,
                  Genius Nutrition, Per4m and RYSE. You will also find many
                  other brands with individual new products in the New Products
                  category.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem eventKey="4">
                <AccordionHeader>
                  How does the order process work?
                </AccordionHeader>
                <AccordionBody>
                  You can order all products at the corresponding price, with no
                  restrictions on stock. Therefore, please be careful when
                  placing orders. As soon as you have placed your order, you
                  will receive a specific overview of what will be delivered and
                  when. At this point you can also adjust all quantities again.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem eventKey="5">
                <AccordionHeader>What features are available?</AccordionHeader>
                <AccordionBody>
                  You have the chance to discover new brands and products from
                  our portfolio and secure them at a special price. Each
                  participant will also receive an exclusive sample box.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem eventKey="6">
                <AccordionHeader>
                  What is the fastest way to order?
                </AccordionHeader>
                <AccordionBody>
                  <div className="gap-2">
                    We have created a separate list for this event which shows
                    you all products and offers at a glance.
                    <br />
                    <Link
                      to={'/newcomer-week/flavour-list'}
                      className={'text-decoration-underline'}
                    >
                      Here
                    </Link>{' '}
                    you will find all articles of the&nbsp;
                    <Link
                      to={'/newcomer-week/brands'}
                      className={'text-decoration-underline'}
                    >
                      NEW BRANDS
                    </Link>{' '}
                    as well as all offers of the category&nbsp;
                    <Link
                      to={'/newcomer-week/products'}
                      className={'text-decoration-underline'}
                    >
                      NEW PRODUCTS
                    </Link>
                    .
                  </div>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem eventKey="7">
                <AccordionHeader>
                  Do you have any further questions?
                </AccordionHeader>
                <AccordionBody>
                  Please get in touch with our Customer Service (Tel: +31
                  777201-200) or your sales contact.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
            <div style={{ height: '1rem' }} aria-label="spacer" />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={8} className="gap-4 text-end">
            <Link href={'/'}>
              <button
                style={{
                  backgroundColor: 'rgb(37, 74, 145)',
                  color: 'white',
                  border: 'none',
                  padding: '10px',
                }}
              >
                <FontAwesomeIcon
                  icon={faArrowRightFromBracket}
                  className={'me-2'}
                />
                Exit event
              </button>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HomeLandingPage;
