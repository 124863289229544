import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import AddressTable from './AddressTable/AddressTable';
import { OrderInterface } from '../../../../Interface/Order';
export default function Addresses({ order }: { order: OrderInterface }) {
  return (
    <>
      <Row className="pt-4">
        <Col className="mb-4 mb-md-0">
          {order.invoice_address && (
            <AddressTable
              address={order.invoice_address}
              title="Invoice Address"
            />
          )}
        </Col>
        <Col>
          {order.shipping_address && (
            <AddressTable
              address={order.shipping_address}
              title="Shipping Address"
            />
          )}
        </Col>
      </Row>
    </>
  );
}
